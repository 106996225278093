const points = {
  7: 0,
  8: 0,
  9: 2,
  1: 1, //for 10
  K: 0,
  Q: 0,
  J: 3,
  A: 1,
};
export default points;
