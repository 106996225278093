const cards = Object.freeze({
  game: [
    "7C",
    "7D",
    "7H",
    "7S",
    "8C",
    "8D",
    "8H",
    "8S",
    "9C",
    "9D",
    "9H",
    "9S",
    "1C",
    "1D",
    "1H",
    "1S",
    "AC",
    "AD",
    "AH",
    "AS",
    "JC",
    "JD",
    "JH",
    "JS",
    "KC",
    "KD",
    "KH",
    "KS",
    "QC",
    "QD",
    "QH",
    "QS",
  ],
  score: [
    "2C",
    "2D",
    "2H",
    "2S",
    "3C",
    "3D",
    "3H",
    "3S",
    "4C",
    "4D",
    "4H",
    "4S",
    "5C",
    "5D",
    "5H",
    "5S",
    "6C",
    "6D",
    "6H",
    "6S",
    "black_joker",
    "red_joker",
  ],
  extra: [
    "KD",
    "AS2",
    "KH",
    "KS",
    "QC",
    "QD",
    "QH",
    "QS",
    "JC",
    "JD",
    "JH",
    "JS",
    "KC",
  ],
});

export default cards;
